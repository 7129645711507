@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #0f172a;
  --secondary-color: #e9eef5;
  --success-color: #16a34a;
  --error-color: #ef4444;
  --background-color: #ffffff;
  --font-family: "Sans Serif";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="color"] {
  padding: 3px;
  height: 40px;
  border: none;
  border-radius: 0;
  width: 100%;
}

.form-group input,
.form-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
}

.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group .read-only-value {
  padding: 8px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button[type="submit"] {
  background-color: #4caf50;
  color: #fff;
}

.form-actions button[type="button"] {
  background-color: #f44336;
  color: #fff;
}
