.side-nav {
  width: 200px;
  background-color: #f8f9fa;
  padding: 1rem;
  border-right: 1px solid #dee2e6;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  margin-top: 80px;
}

.side-nav ul {
  list-style-type: none;
  padding: 0;
}

.side-nav li {
  margin: 1rem 0;
}

.side-nav a {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

.side-nav a:hover {
  text-decoration: underline;
}
